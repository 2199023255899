@use "../../styles/mixins" as mixin;

@keyframes load {
  0% {
    width: 0;
    background: var(--text);
  }

  25% {
    width: 40%;
    background: var(--primary);
  }

  50% {
    width: 60%;
    background: var(--primary);
  }

  75% {
    width: 75%;
    background: var(--secondary);
  }

  100% {
    width: 100%;

    background: var(--secondary);
  }
}

.Loader {
  grid-area: content;
  background-color: white;
  background-image: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  margin-left: 9.55%;
  z-index: 4;

  img {
    width: 7%;
  }
  p {
    font-weight: 300;
    margin: 10px;
  }
  .Loading {
    position: relative;
    display: inline-block;
    width: 25%;
    height: 3px;
    background: #f1f1f1;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
  }

  .Loading::after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    animation: load 1s infinite;
  }

  @include mixin.md-lt {
    margin-left: 1px;
    img {
      width: 10%;
    }
  }
  @include mixin.sm-lt {
    margin-left: 0;

    img {
      width: 20%;
    }
  }
}
