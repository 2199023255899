* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  color: var(--text);
  font-size: 1rem;
  overflow-y: scroll;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--primary);
  &:hover {
    color: var(--secondary);
  }
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

/* Dark Mode */

#light {
  background-color: white;
  transition: 0.8s;
  background-image: url("../images/clouds.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}

#dark {
  background-color: var(--darkMode);
  transition: 0.8s;
  h1,
  h2,
  a {
    color: var(--secondary);
  }
  a:hover {
    color: white;
  }
  p,
  span,
  i,
  ::placeholder,
  input,
  textarea,
  .dark-projet {
    color: white;
  }

  input:focus,
  textarea:focus {
    border-bottom: 2px solid var(--secondary);
    animation: borderFormDark 1s;
  }

  .dark {
    background-image: url("../images/stars-min.png");
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
  }

  ::selection {
    background-color: var(--selection);
    color: var(--text);
  }

  .dark-realisations,
  .dark-services {
    p {
      color: var(--text);
    }
  }

  .dark-realisations {
    i {
      color: var(--secondary);
    }
  }
}
