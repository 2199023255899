@use "./assets/styles/mixins" as mixin;

.container {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 150px auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "header content"
    "header content"
    "header content";

  @include mixin.md-lt {
    grid-template-rows: 0px auto auto;
    grid-template-areas:
      "header header"
      "content content"
      "content content";
  }
}
