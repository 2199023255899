@use "./mixins" as mixins;

@font-face {
  font-family: "finger";
  src: url("../fonts/KgTurningTables-8LjA.woff2") format("woff2");
}

button {
  text-align: center;
  padding: 10px;
  font-size: 1.1rem;
  background-color: var(--secondary);
  color: white;
  border: none;
  border-radius: 5px;
  border: 1px solid var(--secondary);
  font-weight: 400;
  transition: 0.8s;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: var(--secondary);
    border-radius: 5px;
    border: 1px solid var(--secondary);
    transition: 0.8s;
  }
}

h1 {
  letter-spacing: 0.2rem;
}
h2 {
  letter-spacing: 0.2rem;
  color: var(--secondary);
  font-size: 3rem;
  font-family: var(--titleFont);
  font-weight: 500;
  @include mixins.lg-lt {
    font-size: 2.8rem;
  }
  @include mixins.sm-lt {
    font-size: 2.2rem;
  }
  @include mixins.xs {
    font-size: 2rem;
  }
}

/*form*/

input,
textarea {
  border: none;
  font-family: var(--font-family);
  color: var(--text);
  background-color: #f7b49530;
}
::placeholder {
  font-size: 1rem;
}

/* white space between link router */
.link {
  color: var(--primary);
  margin: 5px;
  &:hover {
    color: var(--secondary);
  }
}

.active li {
  color: var(--secondary);
}

::selection {
  background-color: var(--selection);
}

.leaflet-control {
  z-index: 1 !important;
}
.leaflet-pane {
  z-index: 1 !important;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 1 !important;
}

.project-toolip {
  background-color: red;
}
