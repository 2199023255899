@use "../../styles/mixins" as mixin;

.headercontainer {
  grid-area: header;
  background-color: var(--primary);
  height: 100vh;
  justify-content: space-between;
  position: fixed;
  max-width: 10%;
  z-index: 9999;
  text-align: center;
  padding-top: 15px;
  img {
    width: 90%;
  }
  li {
    color: white;
    margin: 15px;
    font-size: 1.1rem;
    transition: 0.3s;
    &:hover {
      color: var(--secondary);
      transition: 0.3s;
    }
  }
  .mentions li {
    text-align: center;
    font-size: 0.7rem;
    font-weight: 300;
    padding: 5px;
    margin: 0;
  }
  p {
    padding-top: 15px;
    font-weight: 400;
    font-size: 0.5rem;
    color: white;
  }

  @include mixin.xl {
    .headerMobile {
      display: none;
    }
  }
  @include mixin.lg {
    height: 100%;
    width: 13%;
    max-width: 12%;
    .headerMobile {
      display: none;
    }
  }
}
@include mixin.md-lt {
  .headerMobile {
    z-index: 9999;
    i {
      margin: 5%;
      right: 0;
      top: 0;
      position: fixed;
      color: var(--text);
      font-size: 2rem;
      z-index: 9999;
      padding: 6px;
      border-radius: 3px;
    }
  }
  .headercontainer {
    display: none;
  }
}
