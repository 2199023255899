@use "../../styles/mixins" as mixin;

.dark {
  position: absolute;
  justify-content: end;
  top: 0;
  right: 0;
  margin: 4%;
  i {
    color: #f1c40f;
    margin: 10% 10% 0 0;
    cursor: pointer;
    font-size: 5rem;
    z-index: 1;
  }
  img {
    cursor: pointer;
  }

  @include mixin.md-lt {
    margin: 12% 4%;
    i {
      font-size: 5rem;
    }
    img {
      width: 80%;
    }
  }
  @include mixin.sm-lt {
    margin: 20% 4%;
    i {
      font-size: 3rem;
    }
    img {
      width: 50%;
    }
  }
}
