@use "../../styles/mixins" as mixin;

.headercontainer {
  background-color: var(--primary);
  height: 100vh;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  text-align: center;
  img {
    width: 20%;
  }

  li {
    color: white;
    margin: 15px;
    font-size: 1.3rem;
    cursor: pointer;
    &:hover {
      color: var(--secondary);
    }
  }
  .mentions li {
    text-align: center;
    font-size: 0.8rem;
    font-weight: 300;
    padding: 5px;
    margin: 0;
  }
  p {
    font-weight: 400;
    font-size: 0.5rem;
    color: rgba(255, 255, 255, 0.619);
  }
  .logo {
    transition: 1s ease-in-out;
  }
}

@include mixin.md-lt {
  .headercontainer {
    img {
      width: 30%;
    }
    li {
      font-size: 1.5rem;
    }
  }
}
